<template>
  <div class="page">
    <template v-for="(item, index) in list">
      <van-card :key="index" :thumb="item.titlepic" :title="item.title" @click="showInfo({id: item.id})">
        <template slot="footer">
          <van-button icon="heart-o"></van-button>
        </template>
      </van-card>
    </template>
    <div class="mv-15">
      <van-pagination :items-per-page="size" :total-items="total" @change="getList" mode="simple" v-model="page" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Pagination, Card } from "vant";
Vue.use(Card).use(Pagination);
export default {
  name: "collection",
  data() {
    return {
      isLoading: false,
      list: [],
      favaClass: [],
      selection: [],
      page: 1,
      total: 0,
      size: 20,
    };
  },
  methods: {
    getList() {
      const param = {
        page: this.page,
        size: this.size,
        loading: true,
      };
      this.$http.get("user/fav", param).then((res) => {
        const { total, data } = res;
        this.total = total;
        this.list = data;
      });
    },
    delFava(id) {
      this.$http.post("change_fav", { id, loading: true }).then((res) => {
        if (res.code === 1) {
          this.getList();
        }
      });
    },
    handleSelectionChange(e) {
      this.selection = e.map((item) => item.favaid);
    },
  },
  activated() {
    this.getList();
  },
};
</script>

<style>
</style>